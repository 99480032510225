import React from 'react';
import { Link } from 'gatsby';

import Images from '@components/Images';

import styles from './AboutProject.module.scss';

export function AboutProject() {
  return (
    <div className={styles.aboutContainer}>
      <Images src="new-about-main.jpg" className={styles.image} alt="About" />
      <div className={styles.aboutTextContainer}>
        <div className={styles.aboutText}>
          <h2>О Периодике</h2>
          <p>
            Мы создаем удобный сервис печати фотокниг и помогаем сохранять воспоминания о самых
            важных и дорогих сердцу моментах жизни.
          </p>
          <p>
            Собрать и заказать книгу можно 24/7 из любого места — в удобном конструкторе на сайте
            или в iOS-приложении.
          </p>

          <Link to="/about" className={styles.btn}>
            Подробнее
          </Link>
        </div>
      </div>
    </div>
  );
}
