import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import { filtersType } from '../prop-types/filters';
import styles from './Filters.module.scss';

function Filters({ filters, activeId, handler }) {
  const handleSelectFilter = (value) => {
    handler(value);
  };
  return (
    <div className={styles.filters}>
      {filters.map((f) => (
        <div
          key={f.id}
          onClick={() => handleSelectFilter(f.id)}
          onKeyDown={null}
          className={cn({
            [styles.filtersItem]: true,
            [styles.active]: f.id === activeId,
          })}
          role="button"
          tabIndex="0"
        >
          {f.title}
        </div>
      ))}
    </div>
  );
}

Filters.propTypes = {
  filters: propTypes.arrayOf(filtersType).isRequired,
  activeId: propTypes.string,
  handler: propTypes.func.isRequired,
};

Filters.defaultProps = {
  activeId: '',
};

export default Filters;
